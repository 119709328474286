import React from "react";
import PropTypes from "prop-types";

import { Outcode } from "../../../formGroups/PropertyDetailsGroup";

export default function PropertyDetails({ control, register, getValues }) {
  return (
    <>
      <Outcode
        control={control}
        required={true}
        getValues={getValues}
        register={register}
      />
    </>
  );
}

// PropTypes
PropertyDetails.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};
